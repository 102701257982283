@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "lato", sans-serif;
}

.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}
.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #ff1a75;
    color: #ff1a75;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}
.PresetDateRangePicker_button:active {
    outline: 0
}
.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}
.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}
.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
    direction: rtl
}
.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}
.SingleDatePickerInput__block {
    display: block
}
.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}
.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
    padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}
.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}
.SingleDatePicker {
    position: relative;
    display: inline-block;
}
.SingleDatePicker__block {
    display: block
}
.SingleDatePicker_picker {
    background-color: #fff;
    position: absolute
}
.SingleDatePicker_picker__rtl {
    direction: rtl
}
.SingleDatePicker_picker__directionLeft {
    left: 0
}
.SingleDatePicker_picker__directionRight {
    right: 0
}
.SingleDatePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

}
.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff;
}
.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
}
.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}
.DayPickerKeyboardShortcuts_show {
    width: 22px;
    position: absolute;
    z-index: 2
}
.DayPickerKeyboardShortcuts_show__bottomRight {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover {
    border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover {
    border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: -28px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: -28px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: -28px
}
.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px
}
.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}
.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}
.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}
.CalendarDay:active {
    outline: 0
}
.CalendarDay__defaultCursor {
    cursor: default
}
.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}
.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}
.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}
.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}
.CalendarDay__outside:hover {
    border: 0
}
.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}
.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}
.CalendarDay__selected_span {
    background: #ffa5d2;
    border: 1px double #ffa5d2;
    color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #ff1a75;
    border: 1px double #ff1a75;
    color: #fff
}
.CalendarDay__last_in_range,
.CalendarDay__last_in_range:hover {
    border-style: solid
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #ff1a75;
    border: 1px double #ff1a75;
    color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #ffa5d2;
    border: 1px double #ffa5d2;
    color: #ff1a75
}
.CalendarDay__hovered_span:active {
    background: #ffa5d2;
    border: 1px double #ffa5d2;
    color: #ff1a75
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}
.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}
.CalendarMonth_verticalSpacing {
    border-collapse: separate
}
.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: top;
    caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}
.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}
.CalendarMonthGrid__animating {
    z-index: 1
}
.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}
.CalendarMonthGrid__vertical {
    margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}
.CalendarMonthGrid_month__hidden {
    visibility: hidden
}
.DayPickerNavigation {
    position: relative;
    z-index: 2
}
.DayPickerNavigation__horizontal {
    height: 0
}
.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}
.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}
.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}
.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}
.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}
.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}
.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}
.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}
.DayPicker__horizontal {
    background: #fff
}
.DayPicker__verticalScrollable {
    height: 100%
}
.DayPicker__hidden {
    visibility: hidden
}
.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
    border-radius: 3px
}
.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99999;
}
.DayPicker_portal__vertical {
    position: static;
    position: initial;
    z-index: 99999;
}
.DayPicker_focusRegion {
    outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}
.DayPicker_weekHeaders {
    position: relative
}
.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}
.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}
.DayPicker_weekHeader__vertical {
    left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}
.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}
.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}
.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
    width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}
.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    border-bottom: 1px solid #7e7e7e;
}

.DateInput__small {
    width: 97px
}
.DateInput__block {
    width: 100%
}
.DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
}
.DateInput_input {
    font-weight: 200;
    font-size: 17px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    height: 29px;
    height: 29px;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
}
.DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 5px
}
.DateInput_input__regular {
    font-weight: auto
}
.DateInput_input__readOnly {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #ff1a75;
    border-left: 0
}
.DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
}
.DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}
.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
}
.DateInput_fangShape {
    fill: #fff
}
.DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
}
.DateRangePickerInput {
    background-color: #fff;
    display: inline-block;
    width: 200px;
}
.DateRangePickerInput__disabled {
    background: #f2f2f2
}
.DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
    direction: rtl
}
.DateRangePickerInput__block {
    display: block
}
.DateRangePickerInput__showClearDates {
    padding-right: 30px
}
.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848
}
.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}
.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
    padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}
.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}
.DateRangePicker {
    position: relative;
    display: inline-block
}
.DateRangePicker__block {
    display: block
}
.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}
.DateRangePicker_picker__rtl {
    direction: rtl
}
.DateRangePicker_picker__directionLeft {
    left: 0
}
.DateRangePicker_picker__directionRight {
    right: 0
}
.DateRangePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
}
.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff;
    z-index: 99999;
}
.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
}
.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.MuiDivider-root-267 {
    min-width: 500px;
    color: darkgray;
}

.navbar {
  background: linear-gradient(-45deg,#00b6c2,#aadac5) !important;  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #00b6c2;
  width: 210px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #00b6c2;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.title {
  margin-left: 16px;
}

.list-header {
    color: #ea297c;
    font-family: Gotham;
    font-size: large;
    font-weight: 900;
}
@font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(/static/media/Metropolis-Light.c82170e0.otf) format('truetype');
}

.e-checkbox-wrapper {
    margin-top: 4%;
}

.e-badge.e-badge-warning:not(.e-badge-ghost):not([href]), .e-badge.e-badge-warning[href]:not(.e-badge-ghost) {
    background-color: #00c1b8;
    color: #fff;
}

/* custom code start*/
#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}
/* custom code end*/

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e973";
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: "\e7c8";
}

.custom-field-row {
  margin-bottom: 20px;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-month-view .e-date-header-wrap table col {
  height: 120px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 20px;
}

.schedule-cell-dimension.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells {
  height: 20px;
}

.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visavail {
  position: relative;
}
.visavail-tooltip div.tooltip-top {
  position: absolute;
  text-align: left;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  padding-left: 0;
  width: auto;
  border: 0;

  pointer-events: none;
  line-height: 12px;
  padding-top: 0;
  display: block;
}

.visavail-tooltip div.tooltip-overlay {
  position: absolute;
  text-align: left;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  width: 120px;
  border: 0;
  pointer-events: none;
  line-height: 16px;
  background: #f2f2f2;
  padding: 10px;
  border-radius: 4px;
}

.visavail-ytitle-tooltip div.y-tooltip {
  position: absolute;
  text-align: left;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  width: 190px;
  border: 0;
  pointer-events: none;
  background: #5c5c5c;
  padding: 10px;
  color: #ffffff;
}

div.y-tooltip.right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #5c5c5c transparent transparent;
}

div.y-tooltip.top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  margin-top: 0px;
  border-width: 7px;
  border-style: solid;
  border-color: #5c5c5c transparent transparent transparent;
}
div.y-tooltip.bottom::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  margin-top: -14px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #5c5c5c transparent;
}

.visavail-tooltip .tooltip_has_data {
  /* color of symbol in tooltip if there is data */
  color: #449d44;
}

.rect_pause {
  fill: #0278ae;
}

.rect_action {
  fill: #91d18b;
}

.rect_repas {
  fill: #a6a6a4;
}

.rect_inco-plus {
  fill: #b83b5e;
}

.rect_inco-minus {
  fill: #6a2c70;
}

.visavail-tooltip .tooltip_has_no_data {
  /* color of symbol in tooltip if there is no data */
  color: #c9302c;
}

.visavail .rect_has_data {
  /* blocks that have data */
  fill: #5cb85c;
}

.visavail .rect_has_data:hover {
  fill: #449d44;
}

.visavail .rect_has_no_data {
  /* blocks without data */
  fill: #d9534d;
}

.visavail .rect_has_no_data:hover {
  fill: #c9302c;
}

.visavail .x_tick_emph {
  font-weight: bold;
}

.visavail .ytitle {
  /* y axis labels */
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  overflow: hidden;
}

.visavail .ytitle.link {
  /* y axis label with link */
  cursor: pointer;
  fill: #07c;
}

.visavail .xAxis path,
.visavail .xAxis line {
  display: none;
}

.visavail .xAxis text {
  font-size: 12px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

.visavail .vert_grid {
  fill: none;
  stroke: #dddddd;
  stroke-width: 1px;
}

.visavail .vert_grid_emph {
  fill: none;
  stroke: #dddddd;
  stroke-width: 2px;
}

.visavail .horz_grid {
  fill: none;
  stroke: #dddddd;
  stroke-width: 1px;
}

.visavail .heading {
  font-size: 16px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

.visavail .subheading {
  font-size: 12px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

.visavail .legend {
  font-size: 12px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

.visavail .subchart-xAxis path,
.visavail .subchart-xAxis line {
  color: #777;
}

.visavail .subchart-xAxis text {
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

