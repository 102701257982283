.visavail {
  position: relative;
}
.visavail-tooltip div.tooltip-top {
  position: absolute;
  text-align: left;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  padding-left: 0;
  width: auto;
  border: 0;

  pointer-events: none;
  line-height: 12px;
  padding-top: 0;
  display: block;
}

.visavail-tooltip div.tooltip-overlay {
  position: absolute;
  text-align: left;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  width: 120px;
  border: 0;
  pointer-events: none;
  line-height: 16px;
  background: #f2f2f2;
  padding: 10px;
  border-radius: 4px;
}

.visavail-ytitle-tooltip div.y-tooltip {
  position: absolute;
  text-align: left;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  width: 190px;
  border: 0;
  pointer-events: none;
  background: #5c5c5c;
  padding: 10px;
  color: #ffffff;
}

div.y-tooltip.right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #5c5c5c transparent transparent;
}

div.y-tooltip.top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0%;
  margin-top: 0px;
  border-width: 7px;
  border-style: solid;
  border-color: #5c5c5c transparent transparent transparent;
}
div.y-tooltip.bottom::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  margin-top: -14px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #5c5c5c transparent;
}

.visavail-tooltip .tooltip_has_data {
  /* color of symbol in tooltip if there is data */
  color: #449d44;
}

.rect_pause {
  fill: #0278ae;
}

.rect_action {
  fill: #91d18b;
}

.rect_repas {
  fill: #a6a6a4;
}

.rect_inco-plus {
  fill: #b83b5e;
}

.rect_inco-minus {
  fill: #6a2c70;
}

.visavail-tooltip .tooltip_has_no_data {
  /* color of symbol in tooltip if there is no data */
  color: #c9302c;
}

.visavail .rect_has_data {
  /* blocks that have data */
  fill: #5cb85c;
}

.visavail .rect_has_data:hover {
  fill: #449d44;
}

.visavail .rect_has_no_data {
  /* blocks without data */
  fill: #d9534d;
}

.visavail .rect_has_no_data:hover {
  fill: #c9302c;
}

.visavail .x_tick_emph {
  font-weight: bold;
}

.visavail .ytitle {
  /* y axis labels */
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  overflow: hidden;
}

.visavail .ytitle.link {
  /* y axis label with link */
  cursor: pointer;
  fill: #07c;
}

.visavail .xAxis path,
.visavail .xAxis line {
  display: none;
}

.visavail .xAxis text {
  font-size: 12px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

.visavail .vert_grid {
  fill: none;
  stroke: #dddddd;
  stroke-width: 1px;
}

.visavail .vert_grid_emph {
  fill: none;
  stroke: #dddddd;
  stroke-width: 2px;
}

.visavail .horz_grid {
  fill: none;
  stroke: #dddddd;
  stroke-width: 1px;
}

.visavail .heading {
  font-size: 16px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

.visavail .subheading {
  font-size: 12px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

.visavail .legend {
  font-size: 12px;
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}

.visavail .subchart-xAxis path,
.visavail .subchart-xAxis line {
  color: #777;
}

.visavail .subchart-xAxis text {
  font-family: "Muli", "Helvetica", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  fill: #777;
}
