/* custom code start*/
#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}
/* custom code end*/

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e973";
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: "\e7c8";
}

.custom-field-row {
  margin-bottom: 20px;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-month-view .e-date-header-wrap table col {
  height: 120px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 20px;
}

.schedule-cell-dimension.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells {
  height: 20px;
}
