.list-header {
    color: #ea297c;
    font-family: Gotham;
    font-size: large;
    font-weight: 900;
}
@font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(../fonts/Metropolis-Light.otf) format('truetype');
}

.e-checkbox-wrapper {
    margin-top: 4%;
}

.e-badge.e-badge-warning:not(.e-badge-ghost):not([href]), .e-badge.e-badge-warning[href]:not(.e-badge-ghost) {
    background-color: #00c1b8;
    color: #fff;
}
